import React, { useRef, useEffect } from "react"

import { Link, graphql } from "gatsby"
import styled from "@emotion/styled"

import ScrollToPlugin from "gsap/ScrollToPlugin"
import gsap from "gsap"
import { RichText } from "prismic-reactjs"
import { withPreview } from "gatsby-source-prismic"
import ArrowDown from "../components/arrowDown"
import ArrowRight from "../components/arrowRight"

import Service from "../components/service"
import SEO from "../components/seo"
import VideoStill from "../components/videoStill"
import InstagramFeed from "../components/instagram"
import Reveal from "../components/reveal"

import mobileVideo from "../video/intro_lo.mp4"
import desktopVideo from "../video/intro_hi.mp4"
import PlanBanner from "../components/planBanner"

// without this line, ScrollToPlugin may get dropped by your bundler...
if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollToPlugin)
}

const SectionStrapline = styled.section`
  position: relative;
  background-color: #fff;
  z-index: 1;
`

const Services = styled.section`
  > div:last-of-type {
  }
`

const ServiceInner = styled.div``

const StyledH1 = styled.h1``
const StyledH2 = styled.h2`
  max-width: 400px;
  margin: 0 auto;
`
const StyledH3 = styled.h3`
  letter-spacing: 4px;
`

const StyledP = styled.p``
const StyledH6 = styled.span`
  letter-spacing: 4px;
`

const StyledVideo = styled(VideoStill)``

const Rounded = styled.div`
  width: 150vw;
  height: calc(175px + 5%);
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 150px);
  z-index: 1;
  background-color: #fff;
`

const InstagramSection = styled.section`
  margin: 15% auto;
  @media (min-width: 768px) {
    margin: 10% auto;
  }
`

const BannerSection = styled.section`
  margin: 10% auto;
  @media (min-width: 768px) {
    margin: 10% auto;
  }
`

const ApproachSection = styled.section`
  margin: 10% auto;
  @media (min-width: 768px) {
    margin: 10% auto;
  }
`
const Instagram = styled(InstagramFeed)``

const Promo = styled(Link)`
  position: relative;
  transform: translate(0, -50%);
  @media (min-width: 768px) {
    position: absolute;
    top: 0px;
    right: 20px;
  }
`

// Sort and display the different slice options
const HomePageSlices = ({ slices }) => {
  return slices.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case "category": {
          return (
            <Services key={index} className="container-fluid">
              {slice.items.map((service, i) => {
                return (
                  <ServiceInner key={i}>
                    <Service
                      variant={i + 1}
                      service={service}
                      project={service.featured_project.document}
                    ></Service>
                  </ServiceInner>
                )
              })}
            </Services>
          )
        }
        case "planbanner": {
          return (
            <BannerSection key={index} className="container-fluid">
              <PlanBanner
                title={RichText.asText(slice.primary.plan_banner_title.raw)}
                content={RichText.asText(slice.primary.plan_banner_content.raw)}
                buttonLabel={RichText.asText(
                  slice.primary.plan_button_label.raw
                )}
              ></PlanBanner>
            </BannerSection>
          )
        }
        case "two_column_content": {
          return (
            <ApproachSection key={index} className="container-fluid">
              <div className="row">
                <div className="col-12 col-md-8 col-lg-8">
                  <Reveal>
                    <h2 className="display-2 font-weight-bolder text-left mb-4 mb-md-10 overflow-hidden">
                      {RichText.asText(slice.primary.headline.raw)}
                    </h2>
                  </Reveal>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-5">
                  <Reveal>
                    <h6 className="d-block mb-2 mb-md-3 font-weight-bold">
                      {RichText.asText(slice.primary.title_left.raw)}
                    </h6>
                    <p>{RichText.asText(slice.primary.content_left.raw)}</p>
                  </Reveal>
                </div>
                <div className="offset-md-1 mt-5 mt-md-0 col-12 col-md-5">
                  <Reveal>
                    <h6 className="d-block mb-2 mb-md-3 font-weight-bold">
                      {RichText.asText(slice.primary.title_right.raw)}
                    </h6>
                    <p>{RichText.asText(slice.primary.content_right.raw)}</p>
                  </Reveal>
                </div>
              </div>
            </ApproachSection>
          )
        }
        default:
          return null
      }
    })()
    return res
  })
}

const Page = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const {
    prismicHomepage,
    heroImageDesktop,
    heroImageMobile,
    allInstagramImages,
  } = data

  const { edges: instagramImages } = allInstagramImages

  const servicesRef = useRef(null)

  const sectionRef = useRef(null)
  const heroRef = useRef(null)

  const titleRef = useRef(null)
  const subTitleRef = useRef(null)
  const buttonRef = useRef(null)

  const scrollToElement = el => {
    gsap.to(window, { duration: 0.5, scrollTo: { y: el, offsetY: 50 } })
  }

  useEffect(() => {
    const timeLine = gsap.timeline({ defaults: { ease: "power3.inOut" } })

    timeLine
      .from(titleRef.current, {
        autoAlpha: 0,
        y: 25,
        delay: 3,
        duration: 0.5,
      })
      .from(subTitleRef.current, {
        autoAlpha: 0,
        y: 25,
        duration: 0.5,
        delay: 0.25,
      })
      .from(buttonRef.current, {
        autoAlpha: 0,
        xPercent: -100,
        delay: 0.75,
        duration: 0.5,
      })
    // .to(everChildrens, { autoAlpha: 1, stagger: 0.025 })
    // .to(button, { autoAlpha: 1 })
  }, [])

  useEffect(() => {
    gsap.to(heroRef.current, {
      yPercent: 50,
      ease: "none",
      scrollTrigger: {
        trigger: sectionRef.current,
        start: "top top", // the default values
        // end: "bottom top",
        scrub: true,
      },
    })
    return () => {}
  }, [])

  return (
    <>
      <SEO
        title="Chunk Creative Agency, founded in 2004"
        description="We are a Creative Agency based in Amsterdam and London, founded in 2004. Our services include Brand Strategy & Design, Digital Design and Advertising."
        image={heroImageDesktop.childImageSharp.fluid.src}
      />
      <section className="section-video position-relative" ref={sectionRef}>
        <StyledVideo
          mobileVideo={mobileVideo}
          desktopVideo={desktopVideo}
          desktopImage={heroImageDesktop}
          mobileImage={heroImageMobile}
          videoId="Ly9df1NiA84"
          ref={heroRef}
        >
          <div className="container-fluid d-flex flex-column h-100 justify-content-center">
            <div className="row justify-content-center text-center">
              <div className="col-12 col-md-12 col-lg-12 text-center">
                <StyledH1
                  className="display-2 font-weight-bolder"
                  id="title"
                  ref={titleRef}
                >
                  <span className="white text-uppercase">
                    Chunk Creative Agency
                  </span>
                </StyledH1>
                <StyledH3
                  className="h6 font-weight-light d-none d-lg-block"
                  id="subTitle"
                  ref={subTitleRef}
                >
                  <span className="white text-uppercase">founded in 2004</span>
                </StyledH3>
                <button
                  type="button"
                  className="d-flex align-items-center flex-nowrap mt-1 mt-md-3 mt-lg-5 mx-auto white link--line-under-span"
                  id="button"
                  ref={buttonRef}
                >
                  <StyledH6 className="h6 mr-1 font-weight-light white text-uppercase">
                    watch our showreel
                  </StyledH6>
                  <span className="h7 circle white mx-auto">
                    <ArrowRight />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </StyledVideo>
        <Rounded></Rounded>
      </section>

      <SectionStrapline>
        <div className="container-fluid" style={{ position: "relative" }}>
         {/* <Promo className="promo" to="/we-are-hiring">
            <div>
              <h3 className="h3 font-weight-bolder">
                We're
                <br />
                hiring!
              </h3>
            </div>
          </Promo> */}
          <div className="row justify-content-center text-center">
            <div className="col-12 col-md-12 col-lg-10">
              <Reveal offset="-1%">
                <StyledH2 className="h1 font-weight-bolder mb-3 mb-md-5">
                  {RichText.asText(prismicHomepage.data.title.raw)}
                </StyledH2>
                <StyledP className="font-weight-light">
                  {RichText.asText(prismicHomepage.data.strapline.raw)}
                </StyledP>
                <button
                  type="button"
                  className="d-flex align-items-center flex-nowrap mt-2 mt-md-4 mx-auto black link--line-under-span"
                  onClick={e => scrollToElement(servicesRef.current)}
                >
                  <div>
                    <span className="h6 font-weight-bolder black">
                      {RichText.asText(prismicHomepage.data.button_label.raw)}
                    </span>
                  </div>
                  <span className="h6 circle black">
                    <ArrowDown />
                  </span>
                </button>
              </Reveal>
            </div>
          </div>
        </div>
      </SectionStrapline>

      <div ref={servicesRef}>
        <HomePageSlices slices={prismicHomepage.data.body}></HomePageSlices>

        <InstagramSection>
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-10 col-md-10">
                <Reveal>
                  <div className="text-center">
                    <h2 className="display-2 font-weight-light mb-4 mb-md-6">
                      We have an open culture with a personal approach
                    </h2>
                    <Link
                      to="/about-us"
                      className="d-inline-block black mb-4 mb-md-6 black link--line-under-span"
                    >
                      <div className="d-flex align-items-center">
                        <span className="h5 font-weight-bolder">About us</span>
                        <div className="h5 circle bg-gradient">
                          <ArrowRight />
                        </div>
                      </div>
                    </Link>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
          <Reveal>
            <Instagram images={instagramImages}></Instagram>
          </Reveal>
        </InstagramSection>
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query {
    heroImageDesktop: file(relativePath: { eq: "video/intro_hi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 5000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroImageMobile: file(relativePath: { eq: "video/intro_lo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    prismicHomepage {
      data {
        button_label {
          raw
        }
        strapline {
          raw
        }
        title {
          raw
        }
        body {
          ... on PrismicHomepageBodyCategory {
            id
            slice_label
            slice_type
            items {
              category_content {
                raw
              }
              category_link {
                document {
                  ... on PrismicCategory {
                    id
                    data {
                      slug
                    }
                  }
                }
              }
              category_link_label {
                raw
              }
              category_title {
                raw
              }
              featured_project {
                document {
                  ... on PrismicProject {
                    id
                    uid
                    data {
                      title {
                        raw
                      }
                      client {
                        raw
                      }
                      hero_image {
                        fluid(maxWidth: 5000) {
                          ...GatsbyPrismicImageFluid_withWebp
                        }
                        thumbnails {
                          mobile {
                            fluid(maxWidth: 1600) {
                              ...GatsbyPrismicImageFluid_withWebp
                            }
                          }
                        }
                      }
                      client_logo {
                        fluid(maxWidth: 500) {
                          ...GatsbyPrismicImageFluid_withWebp
                        }
                      }
                      category {
                        document {
                          ... on PrismicCategory {
                            id
                            data {
                              name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageBodyPlanbanner {
            id
            slice_label
            slice_type
            primary {
              plan_banner_content {
                raw
              }
              plan_banner_title {
                raw
              }
              plan_button_label {
                raw
              }
            }
          }
          ... on PrismicHomepageBodyTwoColumnContent {
            id
            slice_label
            slice_type
            primary {
              content_left {
                raw
              }
              content_right {
                raw
              }
              headline {
                raw
              }
              title_left {
                raw
              }
              title_right {
                raw
              }
            }
          }
        }
      }
    }

    allInstagramImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        sourceInstanceName: { eq: "images" }
        absolutePath: { regex: "/instagram/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default withPreview(Page)
